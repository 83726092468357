import { IGetMenuResponse } from "components/layout/domain/dto/IGetMenuResponse";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useUser } from "modules/user/domains/presentation/contexts/UserContext";
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { useHistory, useLocation } from "react-router-dom";

interface IMenuContext {
  menuPosition: number;
  setMenuPosition: React.Dispatch<React.SetStateAction<number>>;
  menuListContext: IGetMenuResponse[];
  setMenuListContext: React.Dispatch<React.SetStateAction<IGetMenuResponse[] | undefined>>;
  menuId: string;
  setMenuId: React.Dispatch<React.SetStateAction<string>>;
  menuLoading: boolean;
  setMenuLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getCurrentGrandchildrenMenu: () => IGetMenuResponse | undefined;
  verifyRouter: () => boolean;
}

const MenuContext = createContext<IMenuContext>(
  {} as IMenuContext
);

export const MenuProvider: FC = ({ children }) => {
  const [menuPosition, setMenuPosition] = useState(2);
  const [menuListContext, setMenuListContext] = useState<IGetMenuResponse[]>();
  const [menuId, setMenuId] = useState("");
  const [menuLoading, setMenuLoading] = useState(false);
  const { hasAccessRole } = useAuth();

  const { pathname } = useLocation();
  const { currentLocal } = useLocal();
  const { user } = useUser()

  const { replace } = useHistory();


  const verifyScreensWithoutMenu = useCallback((path: string) => {
    /*
    "InserirERemoverBI",
    "AprovarPush",
    "EditarPlanoBasicoEPremium" 
    */

    if (path.includes('private/onlinePayment/config')) {
      if (!hasAccessRole('AdminGlobal') && !hasAccessRole('HabilitarPagamentoOnline')) {
        replace("/private/access-denied");
        return false;
      }
    }
    if (path.includes('/private/BI/configure')) {
      if (!hasAccessRole('InserirERemoverBI')) {
        replace("/private/access-denied");
        return false;
      }
    }
    if (path.includes('/private/plans/config')) {
      if (!hasAccessRole('EditarPlanoBasicoEPremium')) {
        replace("/private/access-denied");
        return false;
      }
    }
    if (path.includes('/private/admin/aprovacao')) {
      if (!hasAccessRole('AprovarPush')) {
        replace("/private/access-denied");
        return false;
      }
    }

    return true;
  }, [hasAccessRole, replace])

  const verifyRouter = useCallback(() => {
    if (menuListContext) {
      const allRouters = [...menuListContext.map(item => item.route), ...menuListContext.flatMap(item => item.submenus).map(item => item.route)]
      const path = pathname
      const allowedMenus = [
        '/private',
        '/private/plans',
        '/private/cadastrartutorial',
        '/private/plans/config',
        '/private/admin-novidades',
        '/private/bi/configure',
        '/private/catalog/product/add',
        '/private/catalog/product/edit',
        '/private/catalog/product/schedule',
        '/private/admin/aprovacao',
        '/private/track',
        '/private/onlinepayment/config',
        '/private/sankhya/detail',
        '/private/omie/detail',
        '/private/everest/detail',
        '/private/crm/campaign/config',
        '/private/access-denied',
        '/private/tickets',
        '/private/email/purchase',
        '/private/bi/purchase',
        '/private/plans/purchase',
        '/private/plans/history',
        '/private/novidades',
        '/private/tutoriais',
        '/private/onboarding',
        '/private/financeiro/conta-digital'
      ];

      const isValid = allRouters.filter(item => item && item !== "").some(item => path.toLowerCase().includes(item!.toLowerCase())) || path === "/private/" || path === "/private";
      const startWith = allowedMenus.filter(m => path.toLowerCase().includes(m!.toLowerCase())).length;


      if (!isValid && !startWith) {
          replace("/private/access-denied");
          return false;
      }
      return verifyScreensWithoutMenu(path);
    }
    return false;

  }, [menuListContext, pathname, replace, verifyScreensWithoutMenu]);



  useEffect(() => {
    if (currentLocal && user) {
      const path = pathname
      const tagManagerArgs = {
        dataLayer: {
          userId: user?.id,
          userName: user?.name,
          localId: currentLocal?.id,
          localName: currentLocal?.name,
          page: path
        },
        dataLayerName: 'PageEvent'
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }, [currentLocal, pathname, user])


  useEffect(() => {
    verifyRouter();
  }, [verifyRouter]);


  const getCurrentGrandchildrenMenu = useCallback(() => {
    if (menuListContext) {
      const path = pathname
      const _menuFlat = menuListContext.flatMap(item => item.submenus)
      const _currentMenu = [..._menuFlat].find(item => item.submenus.some(si => {
        return si.route && path.includes(si.route)
      }))

      if (_currentMenu) {
        return _currentMenu
      } else {
        const _currentMenuByChild = [...menuListContext.flatMap(item => item.submenus).map(item => item)].find(item => item.route && item.submenus.some((gc) => gc.route && path.includes(gc.route)))
        return _currentMenuByChild
      }
    }
  }, [menuListContext, pathname])


  const positionMenuTypeProviderValues = useMemo(
    () => ({
      menuPosition,
      setMenuPosition,
      menuListContext: menuListContext ? menuListContext : [],
      setMenuListContext,
      menuId,
      setMenuId,
      menuLoading,
      setMenuLoading,
      getCurrentGrandchildrenMenu: getCurrentGrandchildrenMenu,
      verifyRouter
    }),
    [
      menuPosition,
      setMenuPosition,
      menuListContext,
      setMenuListContext,
      menuId,
      setMenuId,
      menuLoading,
      setMenuLoading,
      getCurrentGrandchildrenMenu,
      verifyRouter
    ]
  );

  return (
    <MenuContext.Provider value={positionMenuTypeProviderValues}>
      <>{children}</>
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  return context;
};



